import React from 'react';

import './ThankYou.scss';

export function ThankYou({ wasUpdated, request }: any) {
    return (
      <div>
        <img src="https://storage.googleapis.com/aspireiq-widgets/assets/mdu-thankyou-page.svg" />
        <div className="title">Thank you!</div>
        <p className="note">
          We appreciate your time
          {' '}
          {wasUpdated ? 'updating' : 'confirming'}
          {' '}
          your information for us. If anything changes, please reach out so we can keep it up to date!
          <br />
          <br />
          Cheers,
          <br />
          {request.client.name}
        </p>
      </div>
    );
}
