export enum FieldType {
  ACTIVATION = 'ACTIVATION',
  ANNUAL = 'ANNUAL',
  BOOLEAN = 'BOOLEAN',
  EMAIL = 'EMAIL',
  COMMUNITY = 'COMMUNITY',
  DATE = 'DATE',
  HIGHLIGHT = 'HIGHLIGHT',
  IMAGES = 'IMAGES',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  ARRAY = 'ARRAY',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  OWNER = 'OWNER',
  PROGRAM = 'PROGRAM',
  TAG = 'TAG',
  TEXT = 'TEXT',
}
