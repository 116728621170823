import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import '@revfluence/widgets/lib/widgets.min.css';

import { UpdateInfo } from './pages';

export default function App() {
  return (
    <Router basename="/app">
      <Switch>
        <Route path="/:requestId">
          <UpdateInfo />
        </Route>
      </Switch>
    </Router>
  );
}
