// @ts-nocheck // TODO
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';
import {
  map,
  isArray,
  isEmpty,
  isUndefined,
  uniq,
} from 'lodash';
import axios from 'axios';
import produce from 'immer';

import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  LoadSpinner,
} from '@revfluence/widgets';

import {
  useRouteMatch,
  useHistory,
  useParams,
  Switch,
  Route,
} from 'react-router-dom';
import { Select as AntdSelect } from 'antd';

import { FieldType } from '../types/Fields';
import { ThankYou } from './ThankYou';

import './UpdateInfo.scss';
import 'antd/dist/antd.css';

const MILLISECONDS_IN_A_MINUTE = 60000;

const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const {
  ANNUAL,
  ARRAY,
  BOOLEAN,
  DATE,
  EMAIL,
  NUMBER,
  SINGLE_SELECT,
  TEXT,
} = FieldType;

export const utcToLocal = (date: any) => {
  if (!date) {
    return date;
  }
  return new Date(date.getTime() + date.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE);
};

export const localToUTC = (date: any) => {
  if (!date) {
    return date;
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE);
};

const renderValue = (value: any, type: string) => {
  if (value == undefined) return 'None added - Please add!';

  switch (type) {
    case TEXT:
    case EMAIL:
      return value;
    case BOOLEAN:
      return value ? 'Yes' : 'No';
    case ARRAY:
      return value.join(', ');
    case ANNUAL:
    case DATE:
      return utcToLocal(new Date(value)).toLocaleDateString('en-US');
    default:
      return value;
  }
};

const renderChoices = (type: string, choices) => {
  if (type === BOOLEAN) {
    return map(BOOLEAN_OPTIONS, (choice: Record<string, boolean>) => (
      <AntdSelect.Option key={choice.value} value={choice.value}>
        {choice.label}
      </AntdSelect.Option>
    ));
  } if ([SINGLE_SELECT, ARRAY].includes(type)) {
    return map(choices, (choice: string) => (
      <AntdSelect.Option key={choice} value={choice}>
        {choice}
      </AntdSelect.Option>
    ));
  }
  return undefined;
};

const renderField = (field, onChange, fieldsError) => {
  let type = field.schema?.type;
  const hasError = !!fieldsError[field.schemaId];
  const errorMessage = fieldsError[field.schemaId];
  if (type === TEXT && isArray(field.schema.choices)) {
    type = SINGLE_SELECT;
  }
  const mappedChoices = renderChoices(type, field.schema?.choices);
  switch (type) {
    case NUMBER:
      return (
        <Input
          hasError={!!fieldsError[field.schemaId]}
          errorMessage={fieldsError[field.schemaId]}
          value={field.value}
          type="integer"
          onChange={onChange}
        />
      );
    case ANNUAL:
    case DATE:
      return (
        <DatePicker
          hasError={!!fieldsError[field.schemaId]}
          errorMessage={fieldsError[field.schemaId]}
          defaultDate={field.value && utcToLocal(new Date(field.value))}
          onDateSelected={onChange}
        />
      );
    case BOOLEAN:
      return (
        <>
          <AntdSelect
            allowClear
            defaultValue={field.value}
            onChange={onChange}
            className={cx('antd-select', { hasError })}
          >
            {mappedChoices}
          </AntdSelect>
          {hasError && !isEmpty(errorMessage) && (
            <div className="errorMessage">{errorMessage}</div>
          )}
        </>
      );
    case SINGLE_SELECT:
    case ARRAY:
      let value = field.value;
      if (value) {
        value = isArray(field.value) ? uniq(field.value) : [field.value];
      }
      return (
        <>
          <AntdSelect
            mode={field.schema?.type === 'ARRAY' ? 'multiple' : undefined}
            allowClear
            defaultValue={value}
            onChange={onChange}
            className={cx('antd-select', { hasError })}
          >
            {mappedChoices}
          </AntdSelect>
          {hasError && !isEmpty(errorMessage) && (
            <div className="errorMessage">{errorMessage}</div>
          )}
        </>
      );
    default:
      return (
        <Input
          hasError={!!fieldsError[field.schemaId]}
          errorMessage={fieldsError[field.schemaId]}
          value={field.value}
          onChange={onChange}
        />
      );
  }
};

export function UpdateInfo() {
  const [request, setRequest] = useState(null);
  const [acceptsTnC, setAcceptsTnC] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [wasUpdated, setWasUpdated] = useState(false);
  const [fieldsError, setFieldsError] = useState({});

  const match = useRouteMatch();
  const history = useHistory();
  const { requestId } = useParams();

  useEffect(() => {
    axios.get(`/v1/member/requests/${requestId}`)
      .then(({ data: { request, schemas, termsAccepted } }) => {
        setRequest({
          id: request.id,
          client: request.batch.client,
          fields: request.batch.fields.map((f) => ({
            ...f,
            value: request.member.fields[f.schemaId],
            schema: schemas.find((s) => s.id === f.schemaId),
          })),
          termsAccepted,
        });

        const froceEdit = !request.batch.fields.every((f) => !!request.member.fields[f.schemaId] || !f.required);
        if (froceEdit) {
          history.push(`/${requestId}/edit`);
        }
      }).catch(console.log);
  }, []);

  const updateField = useCallback((index, value) => {
    setRequest(produce(request, (draft) => {
      const fieldType = draft.fields[index].schema.type;
      if (fieldType === 'ANNUAL' || fieldType === 'DATE') {
        draft.fields[index].value = localToUTC(value);
      } else {
        draft.fields[index].value = value;
      }
    }));
  }, [request]);

  const isEmailAddressValid = (value) => {
    const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)\b$/i;
    return !!value && !isEmpty(value.match(EMAIL_REGEX));
  };

  const onSave = (isConfirmation?: boolean) => {
    if (!isConfirmation) {
      const errors = {};
      let isError = false;
      request.fields.forEach((f) => {
        let fieldHasError = false;
        if (f.required) {
          switch (f.schema.type) {
            case TEXT:
            case EMAIL:
            case SINGLE_SELECT:
            case ARRAY:
              fieldHasError = isEmpty(f.value);
              break;
            case NUMBER:
            case BOOLEAN:
            case ANNUAL:
            case DATE:
              fieldHasError = isUndefined(f.value);
              break;
            default:
              fieldHasError = isUndefined(f.value) || isEmpty(f.value);
              break;
          }
          errors[f.schemaId] = fieldHasError && 'This field is required';
        }

        if (!fieldHasError) {
          // Field exists but need to validate actual data
          switch (f.schema.type) {
            case EMAIL:
              fieldHasError = !isEmailAddressValid(f.value);
              errors[f.schemaId] = fieldHasError && 'Please enter a valid email';
              break;
            default:
              break;
          }
        }
        isError = isError || fieldHasError;
      });
      setFieldsError(errors);

      if (!acceptsTnC && !request.termsAccepted) {
        isError = true;
        setErrorMsg('Please accept the Aspire Terms of Service and Privacy Policy to continue.');
      } else {
        setErrorMsg(null);
      }

      if (isError) return;
    }

    axios.put(`/v1/member/requests/${requestId}`, {
      isConfirmation,
      request,
    }).then(() => {
      history.push('thankyou');
      setWasUpdated(true);
    }).catch(console.log);
  };

  if (!request) {
    return <div className="page"><LoadSpinner /></div>;
  }

  const header = (
    <>
      <div className="title">Update your information</div>
      <p className="note">
        We want to make sure your information is up-to-date so we can collaborate better. Please take a look and let us know if it is correct and if not, update it using the button below. Thank you!
      </p>
    </>
  );

  return (
    <div className="page">
      <Switch>
        <Route path={`${match.path}/thankyou`}>
          <ThankYou request={request} wasUpdated={wasUpdated} />
        </Route>
        <Route path={`${match.path}/edit`}>
          {header}
          {
            request.fields.map((f, i) => (
              <div key={f.schemaId} className="fieldContainer">
                <div className="label">
                  {f.alias}
                  {f.required && <span className="required"> *</span>}
                </div>
                {renderField(f, (v) => updateField(i, v), fieldsError)}
              </div>
            ))
          }
          <div className="divider" />
          <div className="terms">
            <Checkbox checked={acceptsTnC} onChange={setAcceptsTnC} />
            <span>
              Our program is managed by Aspire. By providing this information, you accept the Aspire
              {' '}
              <a
                href="https://www.aspireiq.com/terms/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              {' '}
              and
              {' '}
              <a
                href="https://www.aspireiq.com/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </span>
          </div>
          {
            !!errorMsg && (
              <p className="error">
                {errorMsg}
              </p>
            )
          }
          <div className="actionsContainer">
            <Button
              label="Save"
              onClick={() => onSave(false)}
            />
          </div>
        </Route>

        <Route path={match.path}>
          {header}
          {
            request.fields.map((f) => (
              <div key={f.schemaId} className="fieldContainer withDividers">
                <div className="label">
                  {f.alias}
                  {f.required && <span className="required"> *</span>}
                </div>
                <div className="value">{renderValue(f.value, f.schema.type)}</div>
              </div>
            ))
          }
          <div className="actionsContainer">
            <Button
              className="mainAction"
              label="Update my Information"
              onClick={() => history.push(`${requestId}/edit`)}
            />
            <Button
              className="secondaryAction"
              label="Looks Good!"
              theme="info"
              onClick={() => {
                onSave(true);
                history.push(`${requestId}/thankyou`);
              }}
            />
          </div>
        </Route>
      </Switch>
    </div>
  );
}
